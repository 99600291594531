// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-hyaluronic-acid-js": () => import("./../../../src/pages/lp/hyaluronic-acid.js" /* webpackChunkName: "component---src-pages-lp-hyaluronic-acid-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-online-js": () => import("./../../../src/pages/online.js" /* webpackChunkName: "component---src-pages-online-js" */),
  "component---src-pages-treatment-hifu-js": () => import("./../../../src/pages/treatment/hifu.js" /* webpackChunkName: "component---src-pages-treatment-hifu-js" */),
  "component---src-pages-treatment-index-js": () => import("./../../../src/pages/treatment/index.js" /* webpackChunkName: "component---src-pages-treatment-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

